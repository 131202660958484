export enum TransactionSettingsActions {
  SET_SLIPPAGE_TOLERANCE = "SET_SLIPPAGE_TOLERANCE",
  RESET_SLIPPAGE_TOLERANCE = "RESET_SLIPPAGE_TOLERANCE",
  SET_TRANSACTION_TIMEOUT = "SET_TRANSACTION_TIMEOUT",
  RESET_TRANSACTION_TIMEOUT = "RESET_TRANSACTION_TIMEOUT",
}

export interface TransactionSettingsAction {
  type: TransactionSettingsActions;
  payload?: number;
}

export interface TransactionSettingsState {
  slippageTolerance: number;
  transactionTimeout: number;
}
