import { BigNumber } from "ethers";
import { polygon, polygonMumbai } from "wagmi/chains";
import { percentageOf } from "_utils/big-number";

export const appConfig = {
  name: "FIDA app",
  url: "https://dapp-interface-fida.vercel.app",
  defaultChainId:
    process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
      ? polygon.id
      : polygonMumbai.id,
  gasLimitMultiplier: percentageOf(BigNumber.from(1), 100),
  localStorage: {
    slippageTolerance: {
      default: 0.5,
      key: "fida-slippage-tolerance",
      max: 49,
      min: 0.1,
    },
    transactionDeadline: {
      default: 5,
      key: "fida-transaction-deadline",
      max: 20,
      min: 1,
    },
  },
};
