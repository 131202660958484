import { SVGProps } from "react";

export const VoteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 12.1799L16.5 13.8199L18.5 15.9999H5.5L7.5 13.8199L6 12.1799L3 15.4499V19.9999C3 21.0999 3.9 21.9999 5 21.9999H19C20.1 21.9999 21 21.0999 21 19.9999V15.4599L18 12.1799Z"
      fill="currentColor"
    />
    <path
      d="M10.5899 14.4199C11.3699 15.2099 12.6399 15.2199 13.4299 14.4299L18.4099 9.44988C19.1899 8.66988 19.1899 7.39988 18.4099 6.61988L14.8699 3.08988C14.0899 2.30988 12.8199 2.30988 12.0399 3.08988L7.08992 8.03988C6.30992 8.81988 6.30992 10.0699 7.07992 10.8599L10.5899 14.4199ZM13.4599 4.49988L16.9899 8.02988L12.0499 12.9699L8.51992 9.43988L13.4599 4.49988Z"
      fill="currentColor"
    />
  </svg>
);
