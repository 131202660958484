import { SecretType } from "@venly/connect";
import { Venly, VenlySubProviderOptions } from "@venly/web3-provider";
import {
  Chain,
  Connector,
  normalizeChainId,
  ProviderRpcError,
  SwitchChainError,
  UserRejectedRequestError,
} from "@wagmi/core";
import { providers } from "ethers";
import { getAddress, hexValue } from "ethers/lib/utils";
import { polygon } from "wagmi/chains";

export class VenlyConnector extends Connector {
  readonly id = "venly";
  readonly name = "Venly Wallet";
  readonly ready = true;

  #provider?: providers.Web3Provider;

  constructor(config: { chains?: Chain[]; options: VenlySubProviderOptions }) {
    super(config);

    Venly.createProviderEngine(this.options).then((provider) => {
      this.#provider = new providers.Web3Provider(
        <providers.ExternalProvider>(<unknown>provider)
      );
    });
  }

  async connect({ chainId }: { chainId?: number } = {}) {
    try {
      await Venly.authenticate();

      const provider = await this.getProvider();
      provider.on("accountsChanged", this.onAccountsChanged);
      provider.on("chainChanged", this.onChainChanged);
      provider.on("disconnect", this.onDisconnect);

      this.emit("message", { type: "connecting" });

      const account = await this.getAccount();

      // Switch to chain if provided
      let id = await this.getChainId();
      let unsupported = this.isChainUnsupported(id);
      if (chainId && id !== chainId) {
        const chain = await this.switchChain(chainId);
        id = chain.id;
        unsupported = this.isChainUnsupported(id);
      }

      return {
        account,
        chain: { id, unsupported },
        provider,
      };
    } catch (error) {
      if (
        /(user closed modal|accounts received is empty)/i.test(
          (<ProviderRpcError>error).message
        )
      )
        throw new UserRejectedRequestError(error);
      throw error;
    }
  }

  async disconnect() {
    if (!this.#provider) return;

    const provider = await this.getProvider();
    provider.removeListener("accountsChanged", this.onAccountsChanged);
    provider.removeListener("chainChanged", this.onChainChanged);
    provider.removeListener("disconnect", this.onDisconnect);

    await Venly.connect()?.logout();
  }

  async getAccount() {
    const provider = await this.getProvider();
    const accounts = await provider.listAccounts();
    return getAddress(accounts[0]);
  }

  async getChainId() {
    const provider = await this.getProvider();
    const network = await provider.getNetwork();
    const chainId = normalizeChainId(network.chainId);
    return chainId;
  }

  async getProvider() {
    if (!this.#provider) {
      const providerEngine = Venly.createProviderEngine(this.options);
      const provider = new providers.Web3Provider(
        <providers.ExternalProvider>(<unknown>providerEngine)
      );

      this.#provider = provider;
      return provider;
    }

    return this.#provider;
  }

  async getSigner() {
    const [provider, account] = await Promise.all([
      this.getProvider(),
      this.getAccount(),
    ]);

    return provider.getSigner(account);
  }

  async isAuthorized() {
    const authenticationResult = await Venly.checkAuthenticated();
    return authenticationResult.isAuthenticated;
  }

  async switchChain(chainId: number) {
    const id = hexValue(chainId);

    try {
      // TODO: map chainId to SecretType
      await Venly.changeSecretType(SecretType.MATIC);

      return (
        this.chains.find((x) => x.id === chainId) ?? {
          id: chainId,
          name: `Chain ${id}`,
          network: `${id}`,
          nativeCurrency: polygon.nativeCurrency,
          rpcUrls: { default: { http: [""] }, public: { http: [""] } },
        }
      );
    } catch (error) {
      const message =
        typeof error === "string" ? error : (<ProviderRpcError>error)?.message;
      if (/user rejected request/i.test(message))
        throw new UserRejectedRequestError(error);
      throw new SwitchChainError(error);
    }
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) this.emit("disconnect");
    else this.emit("change", { account: getAddress(accounts[0]) });
  };

  protected onChainChanged = (chainId: number | string) => {
    const id = normalizeChainId(chainId);
    const unsupported = this.isChainUnsupported(id);
    this.emit("change", { chain: { id, unsupported } });
  };

  protected onDisconnect = () => {
    this.emit("disconnect");
  };
}
