import { SVGProps } from "react";

export const SwapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.14003 11.8601L3.36003 14.6501C3.17003 14.8501 3.17003 15.1601 3.36003 15.3601L6.14003 18.1501C6.45003 18.4701 6.99003 18.2401 6.99003 17.8001V16.0001H13C13.55 16.0001 14 15.5501 14 15.0001C14 14.4501 13.55 14.0001 13 14.0001H6.99003V12.2101C6.99003 11.7601 6.45003 11.5401 6.14003 11.8601ZM20.65 8.65009L17.87 5.86009C17.56 5.54009 17.02 5.77009 17.02 6.21009V8.00009H11C10.45 8.00009 10 8.45009 10 9.00009C10 9.55009 10.45 10.0001 11 10.0001H17.01V11.7901C17.01 12.2401 17.55 12.4601 17.86 12.1401L20.64 9.35009C20.84 9.16009 20.84 8.84009 20.65 8.65009Z"
      fill="currentColor"
    />
  </svg>
);
