import { SVGProps } from "react";

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4001 0.607919C11.0658 0.273633 10.5258 0.273633 10.1915 0.607919L6.00008 4.79078L1.80865 0.599347C1.47437 0.265061 0.934365 0.265061 0.60008 0.599347C0.265794 0.933633 0.265794 1.47363 0.60008 1.80792L4.79151 5.99935L0.60008 10.1908C0.265794 10.5251 0.265794 11.0651 0.60008 11.3993C0.934365 11.7336 1.47437 11.7336 1.80865 11.3993L6.00008 7.20792L10.1915 11.3993C10.5258 11.7336 11.0658 11.7336 11.4001 11.3993C11.7344 11.0651 11.7344 10.5251 11.4001 10.1908L7.20865 5.99935L11.4001 1.80792C11.7258 1.4822 11.7258 0.933633 11.4001 0.607919V0.607919Z"
      fill="currentColor"
    />
  </svg>
);
