import { SVGProps } from "react";

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.09 18C12.35 18 12.565 17.915 12.735 17.745C12.905 17.575 12.99 17.36 12.99 17.1V11.67C12.99 11.43 12.9 11.225 12.72 11.055C12.54 10.885 12.33 10.8 12.09 10.8C11.83 10.8 11.615 10.885 11.445 11.055C11.275 11.225 11.19 11.44 11.19 11.7V17.13C11.19 17.37 11.28 17.575 11.46 17.745C11.64 17.915 11.85 18 12.09 18ZM12 8.58C12.28 8.58 12.515 8.49 12.705 8.31C12.895 8.13 12.99 7.9 12.99 7.62C12.99 7.34 12.895 7.1 12.705 6.9C12.515 6.7 12.28 6.6 12 6.6C11.72 6.6 11.485 6.7 11.295 6.9C11.105 7.1 11.01 7.34 11.01 7.62C11.01 7.9 11.105 8.13 11.295 8.31C11.485 8.49 11.72 8.58 12 8.58ZM12 24C10.3 24 8.72 23.695 7.26 23.085C5.8 22.475 4.53 21.63 3.45 20.55C2.37 19.47 1.525 18.2 0.915 16.74C0.305 15.28 0 13.7 0 12C0 10.32 0.305 8.75 0.915 7.29C1.525 5.83 2.37 4.56 3.45 3.48C4.53 2.4 5.8 1.55 7.26 0.93C8.72 0.31 10.3 0 12 0C13.68 0 15.25 0.31 16.71 0.93C18.17 1.55 19.44 2.4 20.52 3.48C21.6 4.56 22.45 5.83 23.07 7.29C23.69 8.75 24 10.32 24 12C24 13.7 23.69 15.28 23.07 16.74C22.45 18.2 21.6 19.47 20.52 20.55C19.44 21.63 18.17 22.475 16.71 23.085C15.25 23.695 13.68 24 12 24ZM12 22.2C14.8 22.2 17.2 21.2 19.2 19.2C21.2 17.2 22.2 14.8 22.2 12C22.2 9.2 21.2 6.8 19.2 4.8C17.2 2.8 14.8 1.8 12 1.8C9.2 1.8 6.8 2.8 4.8 4.8C2.8 6.8 1.8 9.2 1.8 12C1.8 14.8 2.8 17.2 4.8 19.2C6.8 21.2 9.2 22.2 12 22.2Z"
      fill="currentColor"
    />
  </svg>
);
