import { configureChains } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";

export const defaultChain =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
    ? polygon
    : polygonMumbai;

const apiKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY;

export const { chains, provider, webSocketProvider } = configureChains(
  [defaultChain],
  [alchemyProvider({ apiKey })]
);
