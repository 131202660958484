import { SVGProps } from "react";

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.7235 7.47109C28.7374 7.75283 28.7426 8.03804 28.7426 8.31978C28.7426 16.998 22.1391 27.0015 10.0643 27.0015C6.35478 27.0015 2.90609 25.9146 0 24.0502C0.514783 24.1111 1.03652 24.1424 1.56522 24.1424C4.64348 24.1424 7.47304 23.092 9.71826 21.3337C6.84696 21.2798 4.42087 19.3841 3.58609 16.7754C3.98609 16.8502 4.39826 16.8902 4.82087 16.8902C5.42087 16.8902 6.00174 16.812 6.5513 16.6624C3.54783 16.0572 1.28522 13.405 1.28522 10.2224C1.28522 10.1946 1.28522 10.1685 1.28522 10.1407C2.17043 10.6328 3.18435 10.9285 4.25913 10.9633C2.49739 9.78413 1.33739 7.77718 1.33739 5.49718C1.33739 4.29544 1.66087 3.16674 2.22783 2.19631C5.46435 6.17022 10.3043 8.78239 15.76 9.05717C15.6487 8.57544 15.5913 8.07631 15.5913 7.55978C15.5913 3.93544 18.5287 0.998047 22.1548 0.998047C24.0435 0.998047 25.7496 1.79283 26.9478 3.06935C28.4417 2.7737 29.847 2.22761 31.1165 1.47631C30.6261 3.00848 29.5843 4.29544 28.2296 5.10761C29.5583 4.94761 30.8209 4.59805 32 4.07457C31.12 5.39283 30.007 6.54761 28.7235 7.47109Z"
      fill="currentColor"
    />
  </svg>
);
