import { SVGProps } from "react";

export const DiscordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="26"
    viewBox="0 0 34 26"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.2841 2.17749C26.1264 1.16025 23.8192 0.420959 21.4072 0C21.111 0.539654 20.7649 1.2655 20.5263 1.84291C17.9622 1.45436 15.4217 1.45436 12.9048 1.84291C12.6662 1.2655 12.3123 0.539654 12.0134 0C9.59873 0.420959 7.28893 1.16297 5.13121 2.18288C0.779055 8.8097 -0.400741 15.2719 0.189158 21.6424C3.07574 23.8144 5.87318 25.1339 8.62341 25.9973C9.30245 25.0556 9.90807 24.0546 10.4298 22.9996C9.43615 22.6192 8.48445 22.1497 7.58521 21.6046C7.82377 21.4265 8.05713 21.2404 8.28258 21.0488C13.7673 23.6337 19.7266 23.6337 25.1459 21.0488C25.374 21.2404 25.6073 21.4265 25.8432 21.6046C24.9413 22.1523 23.987 22.6218 22.9934 23.0023C23.5151 24.0546 24.1181 25.0584 24.7998 26C27.5527 25.1366 30.3527 23.8172 33.2393 21.6424C33.9314 14.2574 32.0569 7.85452 28.2841 2.17749ZM11.177 17.7246C9.53055 17.7246 8.18032 16.1758 8.18032 14.2898C8.18032 12.4037 9.50172 10.8522 11.177 10.8522C12.8524 10.8522 14.2026 12.401 14.1737 14.2898C14.1763 16.1758 12.8524 17.7246 11.177 17.7246ZM22.2514 17.7246C20.6049 17.7246 19.2547 16.1758 19.2547 14.2898C19.2547 12.4037 20.5761 10.8522 22.2514 10.8522C23.9267 10.8522 25.2769 12.401 25.2481 14.2898C25.2481 16.1758 23.9267 17.7246 22.2514 17.7246Z"
      fill="currentColor"
    />
  </svg>
);
