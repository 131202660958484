import { SVGProps } from "react";

export const PoolIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.68 6.8001C12.29 6.4501 11.7 6.4501 11.31 6.8001C9.35 8.5601 9 10.8401 9 12.0001C9 13.1501 9.35 15.4401 11.32 17.2001C11.71 17.5501 12.3 17.5501 12.69 17.2001C14.65 15.4401 15 13.1601 15 12.0001C15 10.8501 14.65 8.5601 12.68 6.8001Z"
      fill="currentColor"
    />
    <path
      d="M9.04 16.87C8.71 16.95 8.36 17 8 17C5.24 17 3 14.76 3 12C3 9.24 5.24 7 8 7C8.36 7 8.71 7.05 9.04 7.13C9.43 6.57 9.92 6.01 10.53 5.5C9.75 5.19 8.9 5 8 5C4.14 5 1 8.14 1 12C1 15.86 4.14 19 8 19C8.9 19 9.75 18.81 10.53 18.5C9.92 17.99 9.43 17.43 9.04 16.87Z"
      fill="currentColor"
    />
    <path
      d="M16 5C15.1 5 14.25 5.19 13.47 5.5C14.08 6.01 14.57 6.57 14.96 7.13C15.29 7.05 15.64 7 16 7C18.76 7 21 9.24 21 12C21 14.76 18.76 17 16 17C15.64 17 15.29 16.95 14.96 16.87C14.57 17.43 14.08 17.99 13.47 18.5C14.25 18.81 15.1 19 16 19C19.86 19 23 15.86 23 12C23 8.14 19.86 5 16 5Z"
      fill="currentColor"
    />
  </svg>
);
