const config = {
  titleTemplate: "%s | Fida: The Next Generation of Loyalty",
  defaultTitle: "Fida: The Next Generation of Loyalty",
  description: "The Next Generation of Loyalty | Fida",
  openGraph: {
    type: "website",
    locale: "en_UK",
    url: `https://${process.env.NEXT_PUBLIC_SITE_ADDRESS}`,
    site_name: "Fida",
    images: [
      {
        url: `https://${process.env.NEXT_PUBLIC_SITE_ADDRESS}/images/og.jpg`,
        width: 1200,
        height: 630,
        alt: "The Next Generation of Loyalty | Fida",
        type: "image/jpeg",
      },
    ],
  },
  twitter: {
    handle: "@fidaapp",
    site: "@fidaapp",
    cardType: "summary_large_image",
  },
};

export default config;
