import { Hash } from "@wagmi/core";

export type ADDRESS_TYPE = "FACTORY" | "FID" | "GOVERNOR" | "ROUTER";
export type POLYGON_ADDRESS_TYPE = "FACTORY" | "ROUTER";

export const ADDRESSES: Record<number, Record<ADDRESS_TYPE, Hash>> = {
  137: {
    FACTORY: "0xCe70B995C2FDEF2BFF38d5D4d37cf001a368170A",
    FID: "0x9C9cf919799e50C9F8dAcFA9554249A5C220379a",
    GOVERNOR: "0x",
    ROUTER: "0x36923B8d109A0efF080A89873075032D71025602",
  },
  80001: {
    FACTORY: "0x31252a618739fA745e9691Bac4c11a44c9525974",
    FID: "0xd1c39e7cd876517f8A446eeF443B046E55dFE1F7",
    GOVERNOR: "0xE6834e21364c26d33bd25dE8f4467200a7275e5C",
    ROUTER: "0xa4371394029FD8bAaaEDE896357b292e28f71f47",
  },
};

export const POLYGON_ADDRESSES: Record<
  number,
  Record<POLYGON_ADDRESS_TYPE, Hash>
> = {
  137: {
    FACTORY: "0x5757371414417b8c6caad45baef941abc7d3ab32",
    ROUTER: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
  },
  80001: {
    FACTORY: "0x5757371414417b8c6caad45baef941abc7d3ab32",
    ROUTER: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
  },
};
