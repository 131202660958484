import { SVGProps } from "react";

export const SmallNumbersPlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.75 10.5C4.34 10.5 4 10.16 4 9.75C4 9.34 4.34 9 4.75 9H6C6.55 9 7 9.45 7 10V14.25C7 14.66 6.66 15 6.25 15C5.84 15 5.5 14.66 5.5 14.25V10.5H4.75ZM9.75 9C9.34 9 9 9.34 9 9.75C9 10.16 9.34 10.5 9.75 10.5H12V11.5H10C9.45 11.5 9 11.95 9 12.5V14C9 14.55 9.45 15 10 15H12.75C13.16 15 13.5 14.66 13.5 14.25C13.5 13.84 13.16 13.5 12.75 13.5H10.5V12.5H12.5C13.05 12.5 13.5 12.05 13.5 11.5V10C13.5 9.45 13.05 9 12.5 9H9.75ZM18.5 15C19.05 15 19.5 14.55 19.5 14V10C19.5 9.45 19.05 9 18.5 9H15.75C15.34 9 15 9.34 15 9.75C15 10.16 15.34 10.5 15.75 10.5H18V11.5H16.5C16.22 11.5 16 11.72 16 12C16 12.28 16.22 12.5 16.5 12.5H18V13.5H15.75C15.34 13.5 15 13.84 15 14.25C15 14.66 15.34 15 15.75 15H18.5Z"
      fill="currentColor"
    />
  </svg>
);
