import { ADDRESSES, POLYGON_ADDRESSES } from "constants/addresses";
import { Token } from "core/types";
import { defaultChain } from "core/wagmiClient/chains";
import { useBlockTimestamp } from "hooks/timestamp";
import { sortBy } from "lodash";
import React, { useMemo, useReducer, useState } from "react";
import { appConfig } from "src/configs/app";
import { transactionSettingsReducer } from "src/_reducers/transaction-settings";
import { getDefaultTransactionSettings } from "src/_reducers/transaction-settings/getDefaults";
import { useAccount, useNetwork } from "wagmi";
import { tokensList } from "./constants";
import { GlobalContext } from "./context";
import { GlobalContextProviderProps } from "./types";

export const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}) => {
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { timestamp, refetchTimestamp } = useBlockTimestamp();

  const chainID = useMemo(
    () => chain?.id ?? appConfig.defaultChainId,
    [chain?.id]
  );

  const isChainSupported = useMemo(
    () => Boolean(chainID && defaultChain.id === chainID),
    [chainID]
  );

  const tokens: Token[] = useMemo(() => {
    const tokens = isChainSupported && chainID ? tokensList[chainID] : [];
    return sortBy(tokens?.tokens, "symbol");
  }, [chainID, isChainSupported]);

  const addresses = useMemo(
    () => (isChainSupported && chainID ? ADDRESSES[chainID] : undefined),
    [chainID, isChainSupported]
  );

  const polygonAddresses = useMemo(
    () =>
      isChainSupported && chainID ? POLYGON_ADDRESSES[chainID] : undefined,
    [chainID, isChainSupported]
  );

  const [transactionSettings, setTransactionSettings] = useReducer(
    transactionSettingsReducer,
    getDefaultTransactionSettings()
  );

  const [openWallet, setOpenWallet] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        chainID,
        isChainSupported,
        addresses,
        polygonAddresses,
        address,
        tokens,
        timestamp,
        refetchTimestamp,
        transactionSettings,
        setTransactionSettings,
        openWallet,
        setOpenWallet,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
