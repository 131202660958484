import { SVGProps } from "react";

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99989 16.1701L5.52989 12.7001C5.13989 12.3101 4.50989 12.3101 4.11989 12.7001C3.72989 13.0901 3.72989 13.7201 4.11989 14.1101L8.29989 18.2901C8.68989 18.6801 9.31989 18.6801 9.70989 18.2901L20.2899 7.71007C20.6799 7.32007 20.6799 6.69007 20.2899 6.30007C19.8999 5.91007 19.2699 5.91007 18.8799 6.30007L8.99989 16.1701Z"
      fill="currentColor"
    />
  </svg>
);
