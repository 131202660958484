import { SVGProps } from "react";

export const GitHubIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6904 0C6.33687 0 0.380859 5.96465 0.380859 13.3288C0.380859 19.2268 4.19071 24.2085 9.48125 25.9745C10.1467 26.0912 10.3963 25.6913 10.3963 25.3414C10.3963 25.0249 10.3796 23.9752 10.3796 22.8589C7.03562 23.4754 6.1705 22.0425 5.90431 21.2928C5.75458 20.9096 5.10574 19.7267 4.54009 19.4101C4.07425 19.1602 3.40878 18.5437 4.52345 18.5271C5.57157 18.5104 6.32023 19.4934 6.56979 19.8933C7.76765 21.9092 9.68089 21.3428 10.4462 20.9929C10.5626 20.1265 10.912 19.5434 11.2947 19.2102C8.3333 18.8769 5.23884 17.7273 5.23884 12.6291C5.23884 11.1795 5.75458 9.97995 6.60306 9.04694C6.46997 8.71372 6.00413 7.34751 6.73616 5.5148C6.73616 5.5148 7.85083 5.16492 10.3963 6.881C11.461 6.5811 12.5923 6.43116 13.7237 6.43116C14.855 6.43116 15.9863 6.5811 17.051 6.881C19.5965 5.14826 20.7112 5.5148 20.7112 5.5148C21.4432 7.34751 20.9773 8.71372 20.8443 9.04694C21.6927 9.97995 22.2085 11.1629 22.2085 12.6291C22.2085 17.744 19.0974 18.8769 16.136 19.2102C16.6185 19.6267 17.0344 20.4264 17.0344 21.676C17.0344 23.4587 17.0178 24.8916 17.0178 25.3414C17.0178 25.6913 17.2673 26.1078 17.9328 25.9745C23.1901 24.2085 26.9999 19.2102 26.9999 13.3288C26.9999 5.96465 21.0439 0 13.6904 0Z"
      fill="currentColor"
    />
  </svg>
);
