import { SVGProps } from "react";

export const VenlyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 560 518"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m554.555 169.796-68.688 26.089c-2.888 1.051-5.688-1.751-4.463-4.64L557.968 5.381c1.137-2.801-1.575-5.69-4.375-4.64L408.078 51.694c-2.8.963-5.075 3.152-6.212 5.953l-98.614 248.898c-1.225 3.152 1.837 6.304 4.987 5.166l61.601-22.588c3.413-1.225 6.563 2.452 4.725 5.691L284.089 448.46c-1.925 3.327-6.825 3.327-8.75 0l-90.476-153.734c-1.838-3.151 1.225-6.916 4.725-5.69l61.601 22.587c3.15 1.138 6.212-2.013 4.987-5.165l-98.701-248.81c-1.138-2.802-3.325-4.904-6.213-5.954L5.922.74C3.036-.222.41 2.58 1.549 5.381l76.476 185.864c1.137 2.802-1.663 5.691-4.463 4.64l-68.25-26.089c-3.5-1.313-6.65 2.451-4.726 5.69L203.763 512.02c2.012 3.327 5.6 5.34 9.538 5.34h133.176c3.851 0 7.526-2.013 9.538-5.34l203.528-336.534c1.662-3.239-1.488-7.003-4.988-5.69Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={226.009}
        y1={0.52}
        x2={590.754}
        y2={99.764}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7735E8" />
        <stop offset={1} stopColor="#C62CF1" />
      </linearGradient>
    </defs>
  </svg>
);
