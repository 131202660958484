import React from "react";
import { useBlockNumber, useProvider } from "wagmi";

export const useBlockTimestamp = () => {
  const [timestamp, setTimestamp] = React.useState(0);

  const provider = useProvider();
  const { data, refetch } = useBlockNumber({
    enabled: false,
  });

  const refetchTimestamp = async () => {
    await refetch();

    if (data) {
      const block = await provider.getBlock(data);

      if (block) setTimestamp(block.timestamp);
    }
  };

  return { timestamp, refetchTimestamp };
};
