import { appConfig } from "../../configs/app";
import {
  TransactionSettingsAction,
  TransactionSettingsActions,
  TransactionSettingsState,
} from "./types";

export const transactionSettingsReducer = (
  state: TransactionSettingsState,
  action: TransactionSettingsAction
): TransactionSettingsState => {
  const { type, payload } = action;

  switch (type) {
    case TransactionSettingsActions.SET_SLIPPAGE_TOLERANCE:
      const slippage = payload
        ? payload
        : appConfig.localStorage.slippageTolerance.default;

      localStorage.setItem(
        appConfig.localStorage.slippageTolerance.key,
        slippage.toString()
      );

      return {
        ...state,
        slippageTolerance: slippage,
      };
    case TransactionSettingsActions.RESET_SLIPPAGE_TOLERANCE:
      localStorage.removeItem(appConfig.localStorage.slippageTolerance.key);

      return {
        ...state,
        slippageTolerance: appConfig.localStorage.slippageTolerance.default,
      };
    case TransactionSettingsActions.SET_TRANSACTION_TIMEOUT:
      const timeout = payload
        ? payload
        : appConfig.localStorage.transactionDeadline.default;

      localStorage.setItem(
        appConfig.localStorage.transactionDeadline.key,
        timeout.toString()
      );

      return {
        ...state,
        transactionTimeout: timeout,
      };
    case TransactionSettingsActions.RESET_TRANSACTION_TIMEOUT:
      localStorage.removeItem(appConfig.localStorage.transactionDeadline.key);

      return {
        ...state,
        transactionTimeout: appConfig.localStorage.transactionDeadline.default,
      };
    default:
      return state;
  }
};
