import { CustomContentProps, SnackbarContent } from "notistack";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Icon } from "_components/Icon";

declare module "notistack" {
  interface VariantOverrides {
    error: {
      description?: string;
    };
  }
}

interface MessageProps {
  title?: string;
  description?: string;
  className?: string;
}

interface ErrorMessageProps extends CustomContentProps {
  description?: string;
}

export const Message: React.FC<MessageProps> = ({ title, description }) => {
  return (
    <div className="min-w-[400px] rounded-lg border border-error bg-[#fbdddf] p-2">
      <div className="flex gap-2 text-error">
        <Icon icon="error" className="h-6 w-6 text-error" />
        <div className="flex flex-col gap-2">
          {title}
          {description && (
            <p className="text-sm text-primary-700">{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const ErrorMessage: ForwardRefRenderFunction<
  HTMLDivElement,
  ErrorMessageProps
> = (props, ref) => {
  const { id, message, description, variant, ...other } = props;

  return (
    <SnackbarContent ref={ref} role="alert">
      <Message title={message} description={description} />
    </SnackbarContent>
  );
};

export default forwardRef(ErrorMessage);
