import { SecretType } from "@venly/connect";
import { VenlyConnector } from "../../connectors/venly";

const options = {
  clientId: process.env.NEXT_PUBLIC_VENLY_CLIENT_ID,
  secretType: SecretType.MATIC,
  environment:
    process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
      ? "production"
      : "staging",
  skipAuthentication: true,
};

export const venlyConnector = new VenlyConnector({ options });
