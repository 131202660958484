import { SVGProps } from "react";

export const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0001 5.98993L19.5301 18.9999H4.47012L12.0001 5.98993ZM2.74012 17.9999C1.97012 19.3299 2.93012 20.9999 4.47012 20.9999H19.5301C21.0701 20.9999 22.0301 19.3299 21.2601 17.9999L13.7301 4.98993C12.9601 3.65993 11.0401 3.65993 10.2701 4.98993L2.74012 17.9999ZM11.0001 10.9999V12.9999C11.0001 13.5499 11.4501 13.9999 12.0001 13.9999C12.5501 13.9999 13.0001 13.5499 13.0001 12.9999V10.9999C13.0001 10.4499 12.5501 9.99993 12.0001 9.99993C11.4501 9.99993 11.0001 10.4499 11.0001 10.9999ZM11.0001 15.9999H13.0001V17.9999H11.0001V15.9999Z"
      fill="currentColor"
    />
  </svg>
);
