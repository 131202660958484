import { AddIcon } from "./icons/Add";
import { ArrowDownwardIcon } from "./icons/ArrowDownward";
import { BackIcon } from "./icons/Back";
import { CheckIcon } from "./icons/Check";
import { CircleCheckIcon } from "./icons/CircleCheck";
import { CloseIcon } from "./icons/Close";
import { CoinbaseWalletIcon } from "./icons/CoinbaseWallet";
import { DiscordIcon } from "./icons/Discord";
import { ErrorIcon } from "./icons/Error";
import { ExpandIcon } from "./icons/Expand";
import { FarmIcon } from "./icons/Farm";
import { GitHubIcon } from "./icons/GitHub";
import { InfoIcon } from "./icons/Info";
import { MetamaskIcon } from "./icons/Metamask";
import { MoreIcon } from "./icons/More";
import { PollIcon } from "./icons/Poll";
import { PoolIcon } from "./icons/Pool";
import { PoolRingIcon } from "./icons/PoolRing";
import { RefreshDisabledIcon } from "./icons/RefreshDisabled";
import { SettingsIcon } from "./icons/Settings";
import { SmallNumbersPlaceholderIcon } from "./icons/SmallNumbersPlaceholder";
import { SwapIcon } from "./icons/Swap";
import { TwitterIcon } from "./icons/Twitter";
import { VenlyIcon } from "./icons/Venly";
import { VoteIcon } from "./icons/Vote";
import { WalletConnectIcon } from "./icons/WalletConnect";
import { WarningIcon } from "./icons/Warning";

export const icons = {
  add: AddIcon,
  "arrow-downward": ArrowDownwardIcon,
  back: BackIcon,
  check: CheckIcon,
  "circle-check": CircleCheckIcon,
  close: CloseIcon,
  coinbase: CoinbaseWalletIcon,
  discord: DiscordIcon,
  error: ErrorIcon,
  expand: ExpandIcon,
  farm: FarmIcon,
  github: GitHubIcon,
  info: InfoIcon,
  metamask: MetamaskIcon,
  more: MoreIcon,
  poll: PollIcon,
  pool: PoolIcon,
  "pool-ring": PoolRingIcon,
  "refresh-disabled": RefreshDisabledIcon,
  settings: SettingsIcon,
  "small-numbers-placeholder": SmallNumbersPlaceholderIcon,
  swap: SwapIcon,
  twitter: TwitterIcon,
  venly: VenlyIcon,
  vote: VoteIcon,
  "wallet-connect": WalletConnectIcon,
  warning: WarningIcon,
};
