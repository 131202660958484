import ErrorMessage from "components/ErrorMessage";
import { NextAdapter } from "next-query-params";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Head from "next/head";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { SnackbarProvider } from "notistack";
import { QueryParamProvider } from "use-query-params";
import { WagmiConfig } from "wagmi";
import SEO from "../next-seo.config";
import { client } from "../src/core/wagmiClient";
import "../src/styles/global.css";
import { GlobalContextProvider } from "../src/_contexts/global/provider";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <WagmiConfig client={client}>
      <GlobalContextProvider>
        <QueryParamProvider adapter={NextAdapter}>
          <Head>
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
            <link rel="shortcut icon" href="/favicons/favicon.ico" />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicons/favicon-16x16.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicons/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="48x48"
              href="/favicons/favicon-48x48.png"
            />
            <link rel="manifest" href="/favicons/manifest.webmanifest" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="theme-color" content="#111111" />
            <meta name="application-name" content="FIDA" />
            <link
              rel="yandex-tableau-widget"
              href="/favicons/yandex-browser-manifest.json"
            />
          </Head>

          <DefaultSeo {...SEO} />

          <SnackbarProvider
            preventDuplicate
            maxSnack={3}
            autoHideDuration={5000}
            Components={{
              error: ErrorMessage,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          />

          <GoogleAnalytics trackPageViews />
          <Component {...pageProps} />
        </QueryParamProvider>
      </GlobalContextProvider>
    </WagmiConfig>
  );
}

export default MyApp;
