import { createClient } from "wagmi";
import { provider, webSocketProvider } from "./chains";
import { metamaskConnector } from "./connectors/metamask";
import { venlyConnector } from "./connectors/venly";

export const client = createClient({
  autoConnect: true,
  connectors: [venlyConnector, metamaskConnector],
  provider,
  webSocketProvider,
});
