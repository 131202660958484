import { isNumeric } from "_utils/is-numeric";
import { appConfig } from "../../configs/app";

export const getDefaultTransactionSettings = () => {
  const isClient = typeof window !== "undefined";

  const slippage = isClient
    ? localStorage.getItem(appConfig.localStorage.slippageTolerance.key)
    : appConfig.localStorage.slippageTolerance.default;
  const timeout = isClient
    ? localStorage.getItem(appConfig.localStorage.transactionDeadline.key)
    : appConfig.localStorage.transactionDeadline.default;

  const slippageNumber = isNumeric(slippage)
    ? Number(slippage)
    : appConfig.localStorage.slippageTolerance.default;
  const timeoutNumber = isNumeric(timeout)
    ? Number(timeout)
    : appConfig.localStorage.transactionDeadline.default;

  const slippageTolerance =
    slippageNumber >= appConfig.localStorage.slippageTolerance.min &&
    slippageNumber <= appConfig.localStorage.slippageTolerance.max
      ? slippageNumber
      : appConfig.localStorage.slippageTolerance.default;
  const transactionTimeout =
    timeoutNumber >= appConfig.localStorage.transactionDeadline.min &&
    timeoutNumber <= appConfig.localStorage.transactionDeadline.max
      ? timeoutNumber
      : appConfig.localStorage.transactionDeadline.default;

  return {
    slippageTolerance,
    transactionTimeout,
  };
};
